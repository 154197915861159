<template>
  <div
    :class="{'has-file': showRemovedBtn}"
    class="c-file-input">
    <div v-show="showRemovedBtn" class="c-file-input__indicator">
      <img :src="getPreviewSrc" alt="icon document">
      <!--      <img :src="`http://91.223.123.199:996/${uploadedFile}`" alt="icon document">-->
    </div>
    <label class="c-file-input__label" :for="`inputfile_${id}_${uploadedFile.slice(-7, -5)}`">
      <span>{{ theFileName }}</span>
      <input
        v-if="id !== -1"
        :id="`inputfile_${id}_${uploadedFile.slice(-7, -5)}`"
        type="file"
        @change="fileChangeHandler"
        :name="`attachment_${id}_${uploadedFile.slice(-7, -5)}`" class="c-file-input__field">
    </label>
    <div @click.stop="clearFileHandler" class="c-file-input__remove">
      <span class="c-file-input__remove__icon">+</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomInputFile',

  props: {
    labelPlaceholder: {
      type: String,
      default: 'Файл не выбран'
    },
    uploadedFile: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: Number,
      required: false,
      default: -1
    }
  },

  data () {
    return {
      value: '',
      name: this.labelPlaceholder,
      files: [],
      preview: this.getFileType(this.uploadedFile) ? `${this.$store.getters.getUrlApi}${this.uploadedFile}` : '',
      showRemovedBtn: false
    }
  },
  mounted () {
    if (this.uploadedFile !== '') {
      this.showRemovedBtn = true
    }
  },
  methods: {
    getFileType (file) {
      if (/\.svg/.test(file) || /\.jpg/.test(file) || /\.jpeg/.test(file) || /\.png/.test(file)) {
        return true
      }
    },
    fileChangeHandler (e) {
      console.log(e)
      this.files = Array.from(e.target.files)
      this.showRemovedBtn = true
      const file = this.files[0]
      file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml'
        ? this.preview = window.URL.createObjectURL(file)
        : this.preview = '/assets/icons/document.svg'
      this.$emit('inputFile', { id: this.id, file: file, link: this.labelPlaceholder })
    },
    clearFileHandler () {
      const el = this.$el.querySelector('.c-file-input__field')
      const wrapper = document.createElement('form')
      this.wrapInputAndReset(el, wrapper)
      this.name = 'Файл не выбран'
      this.$emit('deleteFile', this.id)
    },
    wrapInputAndReset (el, wrapper) {
      console.log(el)
      el.parentNode.insertBefore(wrapper, el)
      wrapper.appendChild(el)

      wrapper.reset()

      const docFrag = document.createDocumentFragment()
      while (wrapper.firstChild) {
        const child = wrapper.removeChild(wrapper.firstChild)
        docFrag.appendChild(child)
      }

      // replace wrapper with document fragment
      wrapper.parentNode.replaceChild(docFrag, wrapper)

      this.files = []
      this.showRemovedBtn = false
    }
  },
  computed: {
    theFileName () {
      if ((this.files.length > 0) && 'name' in this.files[0]) {
        return this.files[0].name
      }
      return this.name
    },
    getPreviewSrc () {
      return this.preview ? this.preview : '/assets/icons/document.svg'
    },
    getUploadsFile () {
      return this.uploadedFile
    }
  },
  watch: {
    getUploadsFile () {
      // console.log('getUploadsFile')
      // this.preview = `http://91.223.123.199:996/${this.uploadedFile}`
      this.showRemovedBtn = true
      this.name = this.labelPlaceholder
      this.preview = this.getFileType(this.uploadedFile) ? `${this.$store.getters.getUrlApi}${this.uploadedFile}` : ''
    }
  }
}
</script>

<style scoped lang="scss">
  .c-file-input {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    max-height: 11rem;
    border: 1px dashed #ddd;
    background-color: #fff;
    border-radius: 2px;
    align-items: center;

    &__indicator {

      > img {
        max-height: 10rem;
        min-height: 5rem;
        width: auto;
        margin: .2rem;
        object-fit: contain;
      }
    }

    &:hover {
      box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
    }

    &__label {
      width: 100%;
      /*height: 100%;*/
      line-height: 2.2;
      color: #999;
      font-size: 1.4rem;
      overflow: hidden;
      word-wrap: break-word;
      z-index: 1;
      margin: 0;
    }

    &__field {
      display: none;
    }

    &__remove {
      display: none;
      top: 0;
      right: 0;
      height: 100%;
      z-index: 2;

      > span {
        background: red;
        border: 1px solid #f00;
        border-radius: 50%;
        color: white;
        font-size: 1.6rem;
        font-weight: bold;
        height: 1.5rem;
        width: 1.5rem;
        line-height: .8;
        padding: 0;
        text-align: center;
        cursor: pointer;
        transform: rotate(45deg) translate(0, -50%);
        opacity: .5;

        &:hover {
          opacity: 1;
        }
      }

      &__icon {
        position: absolute;
        /*margin-left: 1.5rem;*/
        top: 0%;
        right: 0;
      }
    }

  }

  .has-file > .c-file-input__remove {
    display: block;
  }
</style>
